* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}

:root {
  --blueDark: #003153;
  --black: #000708;
  --white: #edeff1;
  --blueGrey: #698996;
  --greenDark: #00534b;
  --blue: #06b6db;
}
